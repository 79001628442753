.help-session-tab {
    width: 90%;
    display: flex !important;

    .ant-radio-button-wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;