.room-item {
    background-color: #00000000;
    border-color: #000000;
    transition: 0.3s;
    &:hover {
        background-color: #00000025 !important;
        border-color: #00000025 !important;
    }
    &:active {
        background-color: #00000050 !important;
        border-color: #00000050 !important;
    }
}

.room-item-selected {
    background-color: #00000025 !important;
    border-color: #00000025 !important;
    transition: 0.3s;
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;