.student-live-session-page-tabs {
    .ant-tabs-nav-list {
        width: 100%;
        display: flex;
    }
    
    .ant-tabs-nav-wrap {
        justify-content: start;
    }
    
    .ant-tabs-tab {
        flex: 1;
        text-align: center;
        justify-content: center;
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;