.chat-layout {
  height: 100%;;
}

.chat-layout .title-box {
  background: white;
  padding: 25px;
  height: 115px;
}

.chat-layout .chat-box {
  background-color: white;
  width: 100%;
  height: 100%;
}

.chat-layout .message-box {
  background-color: white;
  padding: 25px;
  height: 115px;
}

.title-bar {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}

.message-bar {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.message-input {
  width: 100%;
  height: 100%;
}

.chat-content {
  height: 100%;
  overflow: auto;
}

.chat-list {
  height: 1px;
  padding: 0px 25px 0px 25px;
}