
//e7e7e7
.header-search-field {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 15px;
    width: 200px;
    height: 100%;
    transition: 0.3s;
    &:hover {
        background-color: #ffffff !important;
        border-color: #e7e7e7 !important;
    }
    &:focus-within {
        background-color: #ffffff !important;
        border-color: #e7e7e7 !important;
        width: 250px;
    }
    
    .ant-input {
        background-color: transparent;
    }
}

.header-search-field-unanimated {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 15px;
    width: 100%;
    height: 100%;
}

.sticky-header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;