
.upload-profile-pic-overlay {
    border: solid;
    border-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 
    width: 160px;
    height: 160px; 
    background: #00000050; 
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        background: #00000065; 
    }
    &:active {
        background: #00000075; 
    }
}

@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;