@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');



.settings-item {
  transition: 250ms;
  cursor: pointer;

  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;

  border-radius: 5px;

  //transform: scale(1);
  background-color: #4e2e8905;

  &:hover {
    //transform: scale(1.02);
    background-color: #e6e4f4; //#4e2e8925;
  }
  &:active {
    //transform: scale(0.98);
    background-color: #4e2e8915;
  }
}


.profile-pop-over-menu-button {
  transition: 250ms;
  cursor: pointer;

  transform: scale(1);

  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.98);
  }
}

.orange-button {
  background-color: #f26622;
  border-color: #f26622;
  &:hover {
    background-color: #f07647 !important;
    border-color: #f07647 !important;
  }
  &:active {
    background-color: #d84a12 !important;
    border-color: #d84a12 !important;
  }
}

.red-button {
  background-color: #990000;
  border-color: #990000;
  &:hover {
    background-color: #c00000 !important;
    border-color: #c00000 !important;
  }
  &:active {
    background-color: #720000 !important;
    border-color: #720000 !important;
  }
}

.red-link {
  color: #990000;
  color: #990000;
  &:hover {
    color: #c00000 !important;
    color: #c00000 !important;
  }
  &:active {
    color: #720000 !important;
    color: #720000 !important;
  }
}

.orange-link {
    color: #f26622;
    color: #f26622;
    &:hover {
      color: #f07647 !important;
      color: #f07647 !important;
    }
    &:active {
      color: #d84a12 !important;
      color: #d84a12 !important;
    }
  }


.green-link {
    color: #009900;
    color: #009900;
    &:hover {
      color: #00d400 !important;
      color: #00d400 !important;
    }
    &:active {
      color: #008500 !important;
      color: #008500 !important;
    }
  }

.ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
}

.left-aligned-tabs {
  .ant-tabs-nav-wrap {
    justify-content: flex-start;
  }
}

.ant-divider-horizontal {
    width: 25%;
    min-width: 25%;
}

// .ant-switch-checked {
//   background-color: #990000 !important;
// }

// .ant-switch {
//   background-color: #1B1B1B;
// }

.Countdown{
  //margin: 10px auto;
  //padding-bottom: 20px;
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 25px;
}

.card-extra-full {
  .ant-card-extra {
    width: 100%
    ;
  }
}

.slide-enter {
	opacity: .01;
	transform: translateX(100%);
}

.slide-enter.slide-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: all 500ms ease-in-out;
}

.slide-leave {
	opacity: 1;
	transform: translateX(100%);
}

.slide-leave.slide-leave-active {
	opacity: .01;
	transform: translateX(100%);
	transition: all 300ms ease-in-out
}

.notification-pop-over {
  .ant-popover-inner-content {
    //padding-left: 0;
    //padding-right: 0;
    padding: 0
  }
}

.emoji-pop-over {
  transition: opacity 250ms;
  .ant-popover-inner-content {
    padding: 0
  }
}

.hidden-pop-over-visible {
  opacity: 1;
  pointer-events: auto;
}

.hidden-pop-over-hidden {
  opacity: 0;
  pointer-events: none;
}

.progress-non-white-color {
  .ant-progress-text {
    color: rgba(0, 0, 0, 0.45);
  }
}

.progress-purple-color {
  .ant-progress-text {
    color: #4e2e89 !important
  }
}

.hidden-number-field-arrows {
  input {
    //padding-bottom: -5px !important;
    height: 50px !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.black-fade {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.0) 50%,
      rgba(0, 0, 0, 0.2) 60%,
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0.9) 95%,
      rgba(0, 0, 0, 1.0) 100%
  );
}

.unselected-tag {
  opacity: 0.45;
  transition: 250ms;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }
  &:active {
    transform: scale(0.95);
    opacity: 1;
  }
}

.blog-list-item {
  transition: 250ms;
  background: white;
  cursor: pointer;

  &:hover {
    background: #00000015;
  }
}

.contact-us-item {
  transition: 250ms;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.90);
  }
}


.live-stream-user-item {
  transition: 250ms;
  height: 30px;
  cursor: pointer;
  

  &:hover {
    height: 45px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &:active {
    
  }
}


.center-tabs {
  .ant-tabs-nav-list {
      width: 100%;
      display: flex;
  }
  
  .ant-tabs-nav-wrap {
      justify-content: start;
  }
  
  .ant-tabs-tab {
      flex: 1;
      text-align: center;
      justify-content: center;
      margin: 0 !important;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav {
    margin: 0 !important
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-content-holder {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.live-stream-window {
  // position: fixed;

  *:not(input) {
    user-select: none;
  }
}

// .live-video > div {
//   width: 100%;
//   height: 100%;
// } 
// .live-video {
//   div & {
//     width: 100%;
//     height: 100%;
//   }
// }

.live-stream-message-area {
  input {
    height: 36px;
  }
  .ant-input-group-addon {
    padding: 0px;
  }
}

.card-full-height-bottom {
  .ant-card-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// video::-internal-media-controls-download-button {
//   display:none !important;
// }

// video::-webkit-media-controls-enclosure {
//   overflow:hidden !important;
// }

// video::-webkit-media-controls-panel {
//   width: calc(100% + 30px) !important; /* Adjust as needed */
// }

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

.pg-viewer {
  width: 100% !important;
  height: 100% !important;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
}

.spreadsheet-viewer {
  height: 100% !important;
}

.react-grid-Container {
  width: unset !important;
  height: 100% !important;
}

.react-grid-Main {
  height: 100% !important;
}

.react-grid-Grid {
  min-height: unset !important;
  height: 100% !important;
}

.react-grid-Canvas {
  width: unset !important;
  height: 100% !important;
}

.pg-driver-view > .video-container > video {
  width: 100% !important;
  height: 100% !important;
}

.photo-viewer-container {
  width: 100% !important;
  height: 100% !important;
}

.pg-viewer > .photo-viewer-container > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  object-position: center;
}

.pdf-canvas {
  display: flex !important;
  justify-content: center !important;
}

.react-grid-HeaderCell {
  pointer-events: none !important;
}

.hashtag-btn {
  transition: 250ms;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    // transform: scale(0.95);
  }
}

.ant-mentions-dropdown {
  border-radius: 5px;
  width: 300px;
}

.ant-mentions-dropdown-menu {
  max-height: unset;
}

// .ant-mentions-dropdown-menu {
//   margin-top: 50px !important;
// }



.hashtag-fade {
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.013) 8.1%,
    hsla(0, 0%, 0%, 0.049) 15.5%,
    hsla(0, 0%, 0%, 0.104) 22.5%,
    hsla(0, 0%, 0%, 0.175) 29%,
    hsla(0, 0%, 0%, 0.259) 35.3%,
    hsla(0, 0%, 0%, 0.352) 41.2%,
    hsla(0, 0%, 0%, 0.45) 47.1%,
    hsla(0, 0%, 0%, 0.55) 52.9%,
    hsla(0, 0%, 0%, 0.648) 58.8%,
    hsla(0, 0%, 0%, 0.741) 64.7%,
    hsla(0, 0%, 0%, 0.825) 71%,
    hsla(0, 0%, 0%, 0.896) 77.5%,
    hsla(0, 0%, 0%, 0.951) 84.5%,
    hsla(0, 0%, 0%, 0.987) 91.9%,
    hsl(0, 0%, 0%) 100%
  );;
}


.scroll-to-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 60px;
  height: 60px;

  font-size: 20px;

  border-radius: 50%;
  box-shadow: 0px 20px 25px -5px rgba(0,0,0,0.1) , 0px 10px 10px -5px rgba(0,0,0,0.04);
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: 250ms;

  transform: scale(1);

  &:hover {
    transform: scale(1.1);
    border-radius: 15px;
    font-size: 30px;
    color: #4e2e89;
  }

  &:active {
    transform: scale(0.9);
    font-size: 20px;
  }
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;