
.stats-number-purple {
    display: block;
    height: 200px;
    width: 200px;
    line-height: 200px;

    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: #4e2e89;
    color: white;
    text-align: center;
    font-size: 4em;
}

.stats-number-orange {
    display: block;
    height: 200px;
    width: 200px;
    line-height: 200px;

    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: #f26622;
    color: white;
    text-align: center;
    font-size: 4em;
}

.ant-collapse-arrow {
    color: white !important;
}
  
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;