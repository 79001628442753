
.upload-profile-overlay {
    border: solid;
    border-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 
    width: 125px;
    height: 125px; 
    background: #00000050; 
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        background: #00000065; 
    }
    &:active {
        background: #00000075; 
    }
}

.upload-cover-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 
    width: 100%;
    height: 100%; 
    background: #00000050; 
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        background: #00000065; 
    }
    &:active {
        background: #00000075; 
    }
}

/*.profile-tabs {
    .ant-tabs-nav-operations {
        display: none !important;
    }
}*/

.profile-tabs {
    .ant-tabs-nav-list {
        width: 100%;
        display: flex;
    }
    
    .ant-tabs-nav-wrap {
        justify-content: start;
    }
    
    .ant-tabs-tab {
        flex: 1;
        text-align: center;
        justify-content: center;
    }
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;