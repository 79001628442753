
.slide-selector {
  margin: 10px;
  padding: 6%;
  position: relative;
  width: 125px;
  height: 115px;
  
  display: flex !important;
  justify-content: center;
  align-items: center;
  /*background-color: red;*/
}

.slide-selector-image {
  /*width: 100%;
  height: 100%;*/
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 25px;
  object-fit: contain;
}

.center .slick-center .slide-selector {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.2);
}

.center .slide-selector {
  opacity: 0.8;
  transition: all 300ms ease;
}


.two-slide .slick-slide { transform: translate(-50%, 0); }

.two-slide .slide-selector {
  height: 150px !important;
}
