

.search-field {
    color: white !important;
    background-color: #00000025 !important;
    border-color: #00000025 !important;
    transition: 0.3s;
    &:hover {
        background-color: #00000050 !important;
        border-color: #00000050 !important;
    }
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;