

.live-stream-all-light-theme {
  @text-color: rgba(0, 0, 0, 0.65);
  @disabled-text-color: rgba(0, 0, 0, 0.25);
  @side-accent-color: #4f2d88;
  @side-accent-color-active: #331d63;
  @tab-divider: #f0f0f0;
  @switch-inactive: #1B1B1B;

  @button-active-background: #990000;
  @button-active-background-hover: #c00000;
  @button-active-background-active: #720000;

  @button-background: #1c1c1c;
  @button-background-hover: #262626;
  @button-background-active: #000000;
  
  .theme()
}


.live-stream-all-dark-theme {
  @text-color: rgba(255, 255, 255, 0.65);
  @disabled-text-color: rgba(255, 255, 255, 0.25);
  @side-accent-color: #f15f43;
  @side-accent-color-active: #bd391f;
  @tab-divider: #1a1a1a;
  @switch-inactive: rgba(255, 255, 255, 0.25);

  @button-active-background: #990000;
  @button-active-background-hover: #c00000;
  @button-active-background-active: #720000;

  @button-background: #1c1c1c;
  @button-background-hover: #262626;
  @button-background-active: #000000;
  
  .theme()
}


.live-stream-light-theme {
  @text-color: rgba(255, 255, 255, 0.65);
  @disabled-text-color: rgba(255, 255, 255, 0.25);
  @side-accent-color: #f15f43;
  @side-accent-color-active: #bd391f;
  @tab-divider: #1a1a1a;
  @switch-inactive: rgba(255, 255, 255, 0.25);

  @button-active-background: #990000;
  @button-active-background-hover: #c00000;
  @button-active-background-active: #720000;

  @button-background: #1c1c1c;
  @button-background-hover: #262626;
  @button-background-active: #000000;
  
  .theme()
}


.live-stream-dark-theme {
  @text-color: rgba(0, 0, 0, 0.65);
  @disabled-text-color: rgba(0, 0, 0, 0.25);
  @side-accent-color: #4f2d88;
  @side-accent-color-active: #331d63;
  @tab-divider: #f0f0f0;
  @switch-inactive: #1B1B1B;

  @button-active-background: #990000;
  @button-active-background-hover: #c00000;
  @button-active-background-active: #720000;

  @button-background: #1c1c1c;
  @button-background-hover: #262626;
  @button-background-active: #000000;

  .theme();
}



.live-stream-blue-theme {
  @text-color: rgba(0, 0, 0, 0.65);
  @disabled-text-color: rgba(0, 0, 0, 0.25);
  @side-accent-color: #0066c9;
  @side-accent-color-active: #00509e;
  @tab-divider: #f0f0f0;
  @switch-inactive: #1B1B1B;

  @button-active-background: #009dc4;
  @button-active-background-hover: #00b4e0;
  @button-active-background-active: #007794;

  @button-background: #00315e;
  @button-background-hover: #004585;
  @button-background-active: #000912;

  .theme();
}


.live-stream-red-theme {
  @text-color: rgba(0, 0, 0, 0.65);
  @disabled-text-color: rgba(0, 0, 0, 0.25);
  @side-accent-color: #c90000;
  @side-accent-color-active: #a30000;
  @tab-divider: #f0f0f0;
  @switch-inactive: #1B1B1B;

  @button-active-background: #990000;
  @button-active-background-hover: #c00000;
  @button-active-background-active: #720000;

  @button-background: #4a0000;
  @button-background-hover: #7a0000;
  @button-background-active: #140000;

  .theme();
}


.live-stream-green-theme {
  @text-color: rgba(0, 0, 0, 0.65);
  @disabled-text-color: rgba(0, 0, 0, 0.25);
  @side-accent-color: #2a6b40;
  @side-accent-color-active: #0e2415;
  @tab-divider: #f0f0f0;
  @switch-inactive: #1B1B1B;

  @button-active-background: #00c407;
  @button-active-background-hover: #00d608;
  @button-active-background-active: #008c05;

  @button-background: #005203;
  @button-background-hover: #007804;
  @button-background-active: #001f01;

  .theme();
}



.live-stream-orange-theme {
  @text-color: rgba(0, 0, 0, 0.65);
  @disabled-text-color: rgba(0, 0, 0, 0.25);
  @side-accent-color: #ff9419;
  @side-accent-color-active: #bd6f15;
  @tab-divider: #f0f0f0;
  @switch-inactive: #1B1B1B;

  @button-active-background: #a85400;
  @button-active-background-hover: #cc6600;
  @button-active-background-active: #5e2f00;

  @button-background: #d68b00;
  @button-background-hover: #ffa600;
  @button-background-active: #825400;

  .theme();
}























.theme() {
  p {
    color: @text-color
  }

  .ant-tabs {
    color: @text-color
  }

  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: @disabled-text-color;

    &:active {
      color: @disabled-text-color;
    }
  }

  .ant-tabs-tab {
    &:hover {
      color: @side-accent-color;
    }
  }

  .ant-tabs-tab-btn {
    &:active {
      color: @side-accent-color-active;
    }
  }

  .ant-tabs-tab-btn {
    p {
      color: inherit !important
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @side-accent-color
  }

  .ant-tabs-ink-bar {
    background: @side-accent-color
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid @tab-divider;
  }

  .ant-switch-checked {
    background-color: @side-accent-color !important;
  }

  .ant-switch {
    background-color: @switch-inactive;
  }

  .live-stream-active-button {
    .ant-btn-primary {
      background-color: @button-active-background;
      border-color: @button-active-background;
      &:hover {
        background-color: @button-active-background-hover !important;
        border-color: @button-active-background-hover !important;
      }
      &:active {
        background-color: @button-active-background-active !important;
        border-color: @button-active-background-active !important;
      }
    }
  }

  .live-stream-active-link {
    .ant-btn-link {
      color: @button-active-background;
      color: @button-active-background;
      &:hover {
        color: @button-active-background-hover !important;
        color: @button-active-background-hover !important;
      }
      &:active {
        color: @button-active-background-active !important;
        color: @button-active-background-active !important;
      }
    }
  }

  .live-stream-button {
    .ant-btn-primary {
      background-color: @button-background;;
      border-color: @button-background;;
      &:hover {
        background-color: @button-background-hover !important;
        border-color: @button-background-hover !important;
      }
      &:active {
        background-color: @button-background-active !important;
        border-color: @button-background-active !important;
      }
    }
  }
}
@primary-color: #4e2e89;@rate-star-color: #f26622;@progress-text-color: white;@primary-1: #e6e4f4;@alert-info-bg-color: #e6e4f4;